<script setup lang="ts">
import { computed, nextTick, onBeforeMount, ref, watch } from "vue";

import * as analytics from "@telia/b2b-web-analytics-wrapper";
import type {
  SubscriptionType,
  ApiError,
} from "@telia/b2b-rest-client/dist/corp-mobile-subscription-type-service";
import {
  corpAgreements,
  corpMobileCustomerService,
  corpMobileSubscriptionTypeService,
} from "@telia/b2b-rest-client";

import { useScope } from "../../../../composables/scope";
import { useModule } from "../../../../composables/module";
import { useOrganisations } from "../../../../composables/organisations";

import simcardImage from "./img/simcard.png";

import { translateSetup, translateMixin } from "./locales";

import LinkCard from "../../../shared/LinkCard.vue";
import SkeletonTabs from "../../../shared/SkeletonLoaders/SkeletonTabs.vue";
import SkeletonCards from "../../../shared/SkeletonLoaders/SkeletonCards.vue";

import STATE from "../../state.enum";
import { NfAgreementOnTscidDto } from "@telia/b2b-rest-client/dist/corp-agreements/models/NfAgreementOnTscidDto";

type SelectorOptions = {
  label: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
};

type MappedSubscriptionType = {
  subscriptionType: string;
  subscriptionName: string;
  subscriptionCategory: string;
  supportedSimformat: Array<string>;
};

const props = withDefaults(
  defineProps<{
    title: object;
    name: string;
    selectedOrganisation: string;
    scopeId: string;
    refreshLayout: () => void;
  }>(),
  {
    selectedOrganisation: "",
  }
);

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

const mobileProducts = ref<MappedSubscriptionType[]>([]);
const mobileDataProducts = ref<MappedSubscriptionType[]>([]);
const m2mProducts = ref<MappedSubscriptionType[]>([]);
const selectedCustomer = ref("");
const selectedAgreement = ref("");
const customerOptions = ref<SelectorOptions[]>([]);
const agreementOptions = ref<SelectorOptions[]>([]);
const products = ref<MappedSubscriptionType[]>([]);
const t = translateMixin.methods.t;

onBeforeMount(async () => {
  translateSetup(props.title, props.name);

  await setupOrganisation();
});

const {
  refreshTheLayout,
  hasSelectedOrganisation,
  isLoading,
  status,
  moduleContainer,
  moduleLoader,
  Module,
  ModuleEmptyState,
} = useModule(props, emit);

const { selectedOrganisation } = useOrganisations();

const { scopeId } = useScope();

const footerDisabled = computed(() => {
  return status.value !== STATE.PRODUCTS_RETRIEVED;
});

const inlineAgreementOptions = computed(() => {
  return [...agreementOptions.value].splice(1);
});

const inlineCustomerOptions = computed(() => {
  return [...customerOptions.value].splice(1);
});

const paddedContent = computed(() => {
  return [STATE.ERROR, STATE.SERVER_ERROR, STATE.NO_CUSTOMER, STATE.NO_AGREEMENT].includes(
    status.value
  );
});

const setCustomer = (event: string) => {
  if (event === "") {
    return;
  }

  selectedCustomer.value = event;
  setupProducts();
  trackEventOrderNow("Kundnummer klickad");
};

const setAgreement = (event: string) => {
  if (event === "") {
    return;
  }
  selectedAgreement.value = event;
  setupProducts();
  trackEventOrderNow("Avtal klickad");
};

const getProductHref = (product: MappedSubscriptionType) => {
  const uriEncodedProductType = encodeURIComponent(product.subscriptionType ?? "");
  if (product.subscriptionCategory === "M2M") {
    return `/foretag/mybusiness/${scopeId.value}/bestall/oppna-iot-abonnemang/${selectedOrganisation.value}/${selectedCustomer.value}/${selectedAgreement.value}/${uriEncodedProductType}`;
  }
  return `/foretag/mybusiness/${scopeId.value}/bestall/oppna-mobilt-abonnemang/${selectedOrganisation.value}/${selectedCustomer.value}/${selectedAgreement.value}/${uriEncodedProductType}`;
};

const getCustomer = async () => {
  const { results } =
    await corpMobileCustomerService.MobileCustomerControllerService.getMobileCustomer(
      scopeId.value,
      {
        tscId: selectedOrganisation.value,
      }
    );

  return results;
};

const getNFA = async () => {
  const tscids = [selectedOrganisation.value];
  const { agreements } = await corpAgreements.NfAgreementsControllerService.getNfAgreementsOnScope(
    scopeId.value,
    tscids
  );
  return agreements;
};

const getAgreements = async () => {
  const { results } =
    await corpAgreements.MobileAgreementsControllerService.getMobileAgreementsNewAuth(
      scopeId.value,
      {
        tscId: selectedOrganisation.value,
        agreementLevels: ["RAM", "UNDER"],
      }
    );
  return results;
};

const getProducts = async (): Promise<MappedSubscriptionType[]> => {
  const { results } =
    await corpMobileSubscriptionTypeService.MobileSubscriptionTypeControllerService.getMobileCustomer(
      scopeId.value,
      selectedOrganisation.value,
      selectedAgreement.value
    );

  return (
    results?.map((product: SubscriptionType) => ({
      subscriptionName: product.subscriptionName ?? "",
      subscriptionCategory: product.subscriptionCategory ?? "",
      subscriptionType: product.subscriptionType ?? "",
      supportedSimformat: product.supportedSimformat ?? [],
    })) ?? []
  );
};

const setupOrganisation = async () => {
  if (!hasSelectedOrganisation.value) {
    status.value = STATE.PRECONDITION_NOT_MET;
    return;
  }

  status.value = STATE.LOADING;
  selectedCustomer.value = "";
  selectedAgreement.value = "";
  customerOptions.value = [];
  agreementOptions.value = [];
  try {
    const [customers, agreements, nfaAgreements] = await Promise.all([
      getCustomer(),
      getAgreements(),
      getNFA(),
    ]);

    if (customers && customers.length < 1) {
      status.value = STATE.NO_CUSTOMER;
      return;
    }

    if (agreements && agreements.length < 1) {
      status.value = STATE.NO_AGREEMENT;
      return;
    }

    if (agreements && isNFA(nfaAgreements ?? [], agreements[0].agreementNumber ?? "")) {
      status.value = STATE.NO_AGREEMENT;
      return;
    }

    customerOptions.value = [
      {
        label: t("ORDER_NOW_MFA_RAM.PLACEHOLDER_CUSTOMER"),
        value: "",
        disabled: true,
        selected: true,
      },
    ];
    if (customers) {
      customers.forEach((customer) =>
        customerOptions.value.push({
          label: customer.customerNumber ?? "",
          value: customer.customerNumber ?? "",
        })
      );
    }

    agreementOptions.value = [
      {
        label: t("ORDER_NOW_MFA_RAM.PLACEHOLDER_AGREEMENT"),
        value: "",
        disabled: true,
        selected: true,
      },
    ];
    if (agreements) {
      agreements.forEach((agreement) =>
        agreementOptions.value.push({
          label: agreement.agreementNumber ?? "",
          value: agreement.agreementNumber ?? "",
        })
      );
    }

    status.value = STATE.ORGANISATION_SET;
  } catch {
    status.value = STATE.ERROR;
  } finally {
    await nextTick();
    refreshTheLayout();
  }
};

const setupProducts = async () => {
  if (selectedCustomer.value.length < 1 || selectedAgreement.value.length < 1) {
    return;
  }
  status.value = STATE.LOADING;
  try {
    products.value = await getProducts();

    mobileProducts.value = filterMobile(products.value);

    mobileDataProducts.value = filterMobileData(products.value);

    m2mProducts.value = filterM2M(products.value);

    agreementGA();
    status.value = products.value.length ? STATE.PRODUCTS_RETRIEVED : STATE.NO_ITEMS;
  } catch (error) {
    let NO_AGREEMENT = false;
    let SERVER_ERROR = false;

    if (isApiError(error)) {
      NO_AGREEMENT = error.body.data.translationKey === "NO_AGREEMENT";
      SERVER_ERROR = error.body.status >= 500;
    }

    status.value = NO_AGREEMENT
      ? STATE.NO_AGREEMENT
      : SERVER_ERROR
        ? STATE.SERVER_ERROR
        : STATE.ERROR;
    products.value = [];
  } finally {
    await nextTick();
    await refreshTheLayout();
  }
};

const isApiError = (error: unknown): error is ApiError => {
  return (error as ApiError).body !== undefined;
};

const filterMobile = (products: MappedSubscriptionType[]) => {
  let filtered = products.filter((product) => product.subscriptionCategory === "VOICE");
  return filtered.slice(0, 2);
};

const filterMobileData = (products: MappedSubscriptionType[]) => {
  let filtered = products.filter((product) => product.subscriptionCategory === "DATA");
  return filtered.slice(0, 2);
};

const filterM2M = (products: MappedSubscriptionType[]) => {
  let filtered = products.filter((product) => product.subscriptionCategory === "M2M");
  return filtered.slice(0, 2);
};

const isNFA = (agreements: NfAgreementOnTscidDto[], agreementNumber: string) => {
  return agreements.some((agreement) => agreement.agreementNumber === agreementNumber);
};

const onTabChange = async () => {
  await nextTick();
  refreshTheLayout();
};

const agreementGA = () => {
  let analyticsMessage = "Avtal med: ";

  if (
    mobileProducts.value.length === 0 &&
    mobileDataProducts.value.length === 0 &&
    m2mProducts.value.length === 0
  ) {
    analyticsMessage += "Inga produkter";
  } else {
    analyticsMessage += mobileProducts.value.length > 0 ? "MOBILE," : "";
    analyticsMessage += mobileDataProducts.value.length > 0 ? "DATA," : "";
    analyticsMessage += m2mProducts.value.length > 0 ? "M2M" : "";
  }

  trackEventOrderNow(analyticsMessage + " klickad");
};

const trackEventOrderNow = (label: string) => {
  analytics.trackEvent(analytics.category.START_PAGE_MODULE, analytics.action.CLICK, label);
};

watch(() => selectedOrganisation.value, setupOrganisation);
</script>

<template>
  <Module
    id="OrderNowMFARAM"
    :title="t('ORDER_NOW_MFA_RAM.MODULE_TITLE')"
    :link-title="t('ORDER_NOW_MFA_RAM.ORDER')"
    :link-href="`/foretag/mybusiness/${scopeId}/bestall/oppna-mobilt-abonnemang`"
    @module-removed="emit('module-removed', $event)"
    :footerDisabled="footerDisabled"
    :showOrganisationWarning="!hasSelectedOrganisation"
    :paddedContent="paddedContent"
    :loading="isLoading"
  >
    <div data-dont-collect class="orders-container" ref="moduleContainer">
      <div v-if="status === STATE.LOADING" ref="moduleLoader" key="moduleLoader">
        <div
          v-if="!customerOptions.length || !agreementOptions.length"
          class="order-now-mfa-ram__skeleton-wrapper"
        >
          <div class="order-now-mfa-ram__skeleton-dropdown-wrapper">
            <telia-skeleton class="order-now-mfa-ram__skeleton-title"></telia-skeleton>
            <telia-skeleton class="order-now-mfa-ram__skeleton-dropdown"></telia-skeleton>
          </div>
          <div class="order-now-mfa-ram__skeleton-dropdown-wrapper">
            <telia-skeleton class="order-now-mfa-ram__skeleton-title"></telia-skeleton>
            <telia-skeleton class="order-now-mfa-ram__skeleton-dropdown"></telia-skeleton>
          </div>
        </div>
        <div v-if="customerOptions.length && agreementOptions.length">
          <div class="order-now-mfa-ram__skeleton-wrapper">
            <telia-skeleton class="order-now-mfa-ram__skeleton-selection"></telia-skeleton>
            <telia-skeleton class="order-now-mfa-ram__skeleton-selection"></telia-skeleton>
          </div>

          <div>
            <SkeletonTabs> </SkeletonTabs>
            <SkeletonCards :numberOfCards="2"></SkeletonCards>
          </div>
        </div>
      </div>

      <div v-if="status === STATE.ORGANISATION_SET" class="OrderNowMFARAM__selectors">
        <telia-form>
          <telia-select
            t-id="customerselect"
            class="telia-select"
            ref="customerSelect"
            :label="t('ORDER_NOW_MFA_RAM.CHOOSE_CUSTOMER')"
            @change="setCustomer($event.target.value)"
            :options="JSON.stringify(customerOptions)"
          >
          </telia-select>
          <telia-select
            t-id="agreementselect"
            class="telia-select"
            ref="agreementSelect"
            :label="t('ORDER_NOW_MFA_RAM.CHOOSE_AGREEMENT')"
            @change="setAgreement($event.target.value)"
            :options="JSON.stringify(agreementOptions)"
          >
          </telia-select>
        </telia-form>
      </div>

      <div
        v-if="status === STATE.PRODUCTS_RETRIEVED || status === STATE.NO_ITEMS"
        class="orderNowMFARAM-dropdown-container"
      >
        <div class="orderNowMFARAM-dropdown">
          <telia-p>
            {{ t("ORDER_NOW_MFA_RAM.CUSTOMER") }}
          </telia-p>
          <b2x-inline-dropdown
            :options="JSON.stringify(inlineCustomerOptions)"
            :value="selectedCustomer ? selectedCustomer : ''"
            heading-element="telia-p"
            heading-variant="paragraph-100"
            icon-size="sm"
            @optionSelected="setCustomer($event.detail)"
          ></b2x-inline-dropdown>
        </div>
        <div
          v-if="status === STATE.PRODUCTS_RETRIEVED || status === STATE.NO_ITEMS"
          class="orderNowMFARAM-dropdown"
        >
          <telia-p>{{ t("ORDER_NOW_MFA_RAM.AGREEMENT") }} </telia-p>
          <b2x-inline-dropdown
            :options="JSON.stringify(inlineAgreementOptions)"
            :value="selectedAgreement ? selectedAgreement : ''"
            heading-element="telia-p"
            heading-variant="paragraph-100"
            icon-size="sm"
            @optionSelected="setAgreement($event.detail)"
          ></b2x-inline-dropdown>
        </div>
      </div>

      <telia-notification
        v-if="status === STATE.ERROR || status === STATE.SERVER_ERROR"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_MFA_RAM.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_MFA_RAM.ERROR.TITLE") }}
        </span>
      </telia-notification>

      <telia-notification
        v-if="status === STATE.NO_CUSTOMER"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_MFA_RAM.NO_CUSTOMER_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_MFA_RAM.NO_CUSTOMER_ERROR.TITLE") }}
        </span>
      </telia-notification>

      <telia-notification
        v-if="status === STATE.NO_AGREEMENT"
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("ORDER_NOW_MFA_RAM.NO_AGREEMENT_ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("ORDER_NOW_MFA_RAM.NO_AGREEMENT_ERROR.TITLE") }}
        </span>
      </telia-notification>

      <module-empty-state v-if="status === STATE.NO_ITEMS">
        {{ t("ORDER_NOW_MFA_RAM.NO_ITEMS") }}
      </module-empty-state>

      <telia-tab
        t-id="tabs"
        variant="light-wide"
        v-if="status === STATE.PRODUCTS_RETRIEVED"
        @vocaTabChange="onTabChange"
      >
        <telia-tab-content
          :t-id="`category-tab-mobile`"
          :name="t('ORDER_NOW_MFA_RAM.MOBILE_SUBSCRIPTION')"
          v-if="status === STATE.PRODUCTS_RETRIEVED && mobileProducts.length > 0"
        >
          <div class="products-list products-list--in-tab">
            <LinkCard
              :key="product.subscriptionName"
              v-for="product in mobileProducts"
              class="product"
              :title="product.subscriptionName"
              @click="trackEventOrderNow('Beställ direkt ' + product.subscriptionName + ' klickad')"
              :href="getProductHref(product)"
              ga-text="OrderNowMFARAM details"
            >
              <template #left>
                <div class="product__img">
                  <img :src="simcardImage" :alt="product.subscriptionName" />
                </div>
              </template>
            </LinkCard>
          </div>
        </telia-tab-content>

        <telia-tab-content
          :t-id="`category-tab-data`"
          :name="t('ORDER_NOW_MFA_RAM.MOBILE_BROADBAND')"
          v-if="status === STATE.PRODUCTS_RETRIEVED && mobileDataProducts.length > 0"
        >
          <div class="products-list products-list--in-tab">
            <LinkCard
              :key="product.subscriptionName"
              v-for="product in mobileDataProducts"
              class="product"
              :title="product.subscriptionName"
              ga-text="OrderNowMFARAM details"
              @click="trackEventOrderNow('Beställ direkt ' + product.subscriptionName + ' klickad')"
              :href="getProductHref(product)"
            >
              <template #left>
                <div class="product__img">
                  <img :src="simcardImage" :alt="product.subscriptionName" />
                </div>
              </template>
            </LinkCard>
          </div>
        </telia-tab-content>

        <telia-tab-content
          :t-id="`category-tab-m2m`"
          :name="t('ORDER_NOW_MFA_RAM.M2M')"
          v-if="status === STATE.PRODUCTS_RETRIEVED && m2mProducts.length > 0"
        >
          <div class="products-list products-list--in-tab">
            <LinkCard
              :key="product.subscriptionName"
              v-for="product in m2mProducts"
              class="product"
              :href="getProductHref(product)"
              :title="product.subscriptionName"
              ga-text="OrderNowMFARAM details"
              @click="trackEventOrderNow('Beställ direkt ' + product.subscriptionName + ' klickad')"
            >
              <template #left>
                <div class="product__img">
                  <img :src="simcardImage" :alt="product.subscriptionName" />
                </div>
              </template>
            </LinkCard>
          </div>
        </telia-tab-content>
      </telia-tab>
    </div>
  </Module>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.products-list {
  margin: 0 $telia-spacing-24;

  &--in-tab {
    margin: $telia-spacing-8 0 0;
  }
}

.product {
  &__img {
    width: $telia-spacing-48;
    height: $telia-spacing-48;
    display: flex;

    img {
      align-self: center;
      color: $telia-purple-500;
      width: 100%;
    }
  }
}

.orders-container {
  position: relative;
  min-height: 7.2rem;
}

.orderNowMFARAM-dropdown {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.orderNowMFARAM-dropdown-container {
  padding: 0 $telia-spacing-16 $telia-spacing-16 $telia-spacing-16;
}

.telia-select {
  margin: 0 $telia-spacing-16 $telia-spacing-16 $telia-spacing-16;
}

.order-now-mfa-ram {
  &__skeleton-wrapper {
    padding-bottom: $telia-spacing-8;
    margin: 0 $telia-spacing-16;
  }

  &__skeleton-dropdown-wrapper {
    margin-bottom: $telia-spacing-16;
  }

  &__skeleton-title {
    width: 18rem;
    height: $telia-spacing-20;
    margin-bottom: $telia-spacing-8;
  }

  &__skeleton-dropdown {
    width: 100%;
    height: $telia-spacing-48;
  }

  &__skeleton-selection {
    width: 25rem;
    height: $telia-spacing-20;
    margin-bottom: $telia-spacing-8;

    &:nth-child(2) {
      width: 20rem;
    }
  }
}
</style>
