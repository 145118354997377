import { corpSupportCsmDisturbance } from "@telia/b2b-rest-client";

export const getSystemDisturbances = (
  xAuthScopeId: string,
  tscId: string,
  acceptLanguage?: string,
): ReturnType<
  typeof corpSupportCsmDisturbance.DisturbanceControllerService.getDisturbanceServices1
> => {
  return corpSupportCsmDisturbance.DisturbanceControllerService.getDisturbanceServices1(
    xAuthScopeId,
    acceptLanguage,
    undefined,
    tscId
  );
};
