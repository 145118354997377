<script setup lang="ts">
import { corpInstalledbaseSearch } from "@telia/b2b-rest-client";
import { corpMobileSimcards } from "@telia/b2b-rest-client";
import { logError } from "@telia/b2x-logging";
import { onBeforeMount, ref, computed, watch } from "vue";
import { trackPukCodeInteraction } from "../../../../utils/trackGA4";
import { translateSetup, translateMixin } from "./locales";
import { useFlag } from "@unleash/proxy-client-vue";
import { FeatureFlags } from "../../../../feature-flags/featureFlags";
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import STATE from "../../state.enum";

interface Option extends corpInstalledbaseSearch.SubscriptionUI {
  placeholder?: boolean;
}

const t = translateMixin.methods.t;

const props = defineProps<{
  scopeId: string;
  number?: Option;
}>();

const emit = defineEmits<{
  (e: "status", status: STATE): void;
  (e: "module-removed", event: Event): void;
}>();

onBeforeMount(() => {
  translateSetup();
});

const status = ref(STATE.RESET);
const pukCode = ref<string>("");
const pukShowMissingNameEnabled = useFlag(FeatureFlags.PUK_MISSING_NAME);

const name = computed(() => {
  if (!props.number) return "";
  if (props.number?.reference) return props.number.reference;

  return pukShowMissingNameEnabled.value ? t("PUK.MISSING_NAME") : "";
});

const department = computed(() => {
  if (props.number && !props.number?.description) return " ";
  return props.number?.description === "" ? " " : props.number?.description;
});

const shouldShowName = computed(() => {
  return !props.number || name.value;
});

const statusIs = (state: STATE) => {
  return status.value === state;
};

const findPukCode = async () => {
  if (!props.number || !props.number.id) return;

  status.value = STATE.LOADING;

  await corpMobileSimcards.MobileSimcardsControllerService.getPukBasedOnC2BCache(
    props.scopeId,
    props.number.id
  )
    .then((response) => {
      analytics.trackEvent(
        analytics.category.START_PAGE_MODULE,
        analytics.action.COMPLETED,
        "PUK hittat"
      );

      trackPukCodeInteraction("mobile_puk_code_view");

      pukCode.value = response.puk ?? "";
      status.value = STATE.SUCCESS;
    })
    .catch(() => {
      logError("b2b-dashboard", "Failed to find puk code in the Puk module");

      analytics.trackEvent(
        analytics.category.START_PAGE_MODULE,
        analytics.action.ERROR,
        "Inget PUK hittat"
      );

      status.value = STATE.ERROR;
    });
};
const resetPuk = () => {
  pukCode.value = "";
  status.value = STATE.RESET;
};
const onCopyPuk = () => {
  analytics.trackEvent(
    analytics.category.START_PAGE_MODULE,
    analytics.action.CLICK,
    "PUK kopierat"
  );
};
const onStatusChange = (status: STATE) => {
  emit("status", status);
};

watch(
  () => [props.number],
  () => {
    resetPuk();
    findPukCode();
  }
);

watch(() => status.value, onStatusChange);
</script>

<template>
  <div class="puk-finder">
    <div class="user-details" :class="{ disabled: !number }">
      <telia-heading
        v-show="shouldShowName"
        class="user-name"
        v-text="name || '********'"
        tag="h3"
        variant="subsection-100"
      ></telia-heading>
      <telia-p
        variant="additional-100"
        class="user-department"
        v-text="department || '********'"
      ></telia-p>
    </div>

    <telia-divider></telia-divider>

    <div
      class="puk-field"
      :class="{
        'puk-field--disabled': !pukCode,
      }"
    >
      <div>
        <telia-heading tag="h4" variant="subsection-100">{{ t("PUK.PUK_CODE") }}</telia-heading>

        <div class="puk-field__code">
          <telia-heading
            tag="h5"
            variant="title-100"
            v-text="pukCode || '******'"
            id="pukNumber"
          ></telia-heading>

          <b2x-copy-text-on-click
            v-if="pukCode"
            id-to-copy="pukNumber"
            :aria-label="t('PUK.COPY')"
            :title="t('PUK.COPY')"
            @click="onCopyPuk"
          ></b2x-copy-text-on-click>
        </div>
      </div>

      <b2x-spinner
        class="puk-finder-loader"
        size="small"
        color="core-purple-light"
        v-if="statusIs(STATE.LOADING)"
      ></b2x-spinner>
    </div>

    <div class="puk-finder__alert" v-if="statusIs(STATE.ERROR) && number">
      <telia-notification
        heading-tag="h4"
        variant="inline"
        status="warning"
        html-aria-live="polite"
        html-role="alert"
      >
        <span slot="heading">
          <telia-visually-hidden>
            {{ t("PUK.ERROR.A11Y_STATUS") }}
          </telia-visually-hidden>
          {{ t("PUK.ERROR.TITLE") }}
        </span>
      </telia-notification>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../../styles/voca";

.puk-finder {
  &__alert {
    margin-top: $telia-spacing-16;
  }

  .user-details {
    margin-top: $telia-spacing-16;
    margin-bottom: $telia-spacing-12;

    &.disabled {
      @include telia-color("gray-500");
      user-select: none;
    }
  }
  .puk-field {
    padding-top: $telia-spacing-16;
    padding-bottom: $telia-spacing-8;
    display: flex;
    justify-content: space-between;

    &--disabled {
      @include telia-color("gray-500");
      user-select: none;
      pointer-events: none;
      cursor: not-allowed;
    }

    &__code {
      display: flex;
      align-items: center;
      margin-top: $telia-spacing-8;
    }

    .puk-finder-loader {
      align-self: center;
      margin-right: $telia-spacing-8;
    }
  }
}
</style>
